.app-header {
  background-color: #3498db; /* Header background color */
  color: #fff; /* Text color */
  padding: 15px; /* Padding for the header */
  text-align: center; /* Center the text */
  margin-bottom: 15px;
}

.app-name-link {
  text-decoration: none; /* Remove default underline for the link */
  color: #fff; /* Set link color */
}

.app-name {
  margin: 0; /* Remove default margin */
  font-size: 24px; /* Font size for the app name */
  font-weight: bold; /* Bold font for emphasis */
}
